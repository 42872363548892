import { useUserStore } from '~/stores/user.store'
import { Routes } from '~/routing/routes'

export default defineNuxtRouteMiddleware((to, from) => {
  return
  const userStore = useUserStore()
  if (userStore.isLoggedIn === true) {
    return navigateTo(Routes.Dashboard)
  }
})
